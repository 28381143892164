body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-background-light text-text-light transition-colors duration-200;
  }

  html.dark {
    @apply bg-background-dark text-text-dark;
  }
}

body, #root {
  min-height: 100vh;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body.dark {
  background-color: #1F2937;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.audio-player {
  --primary-color: #3730A3;
  --secondary-color: #6D28D9;
  --text-color: #FFFFFF;
  --background-color: #1F2937;
  --button-bg-color: rgba(255, 255, 255, 0.2);
  --button-hover-bg-color: rgba(255, 255, 255, 0.3);
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color)) !important;
  color: var(--text-color) !important;
  user-select: none;
}

.audio-player-inner {
  background-color: transparent !important;
}

.audio-player-expand-btn,
.audio-player-controls button,
.audio-player-volume button {
  color: var(--text-color) !important;
}

.audio-player-play-btn {
  background-color: var(--button-bg-color) !important;
  color: var(--text-color) !important;
  transition: background-color 0.3s ease;
}
.audio-player-play-btn:hover {
  background-color: var(--button-hover-bg-color) !important;
}
.audio-player-play-btn svg {
  fill: var(--text-color);
  stroke: var(--text-color);
}

.audio-player-progress-bar,
.audio-player-volume-bar {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.audio-player-tracklist,
.audio-player-info {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.audio-player-tracklist-header,
.audio-player-info-header {
  background-color: var(--primary-color) !important;
}

.audio-player-tracks li:hover,
.audio-player-tracks li.active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}